import React, { useReducer } from 'react';
import Layout from "../components/Layout";
import withTrans from '../i18n/withTranslation';
import emailjs from 'emailjs-com';

const initState = {
  inputName: "",
  inputEmail: "",
  inputMessage: "",
  success: false,
  fail: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "value":
      return {
        ...state,
        [action.name]: action.value
      }

    default:
      return state;
  }
}

const ContactPage = ({ data, t }) => {

  const [state, dispatch] = useReducer(reducer, initState);

  const { inputName, inputEmail, inputMessage, success, fail } = state;

  const onSubmit = (e) => {
    e.preventDefault();

    const emailTemplate = {
      reply_to: inputEmail,
      message: inputMessage,
      from_name: inputName
    }

    emailjs.send('gmail', 'template_hpmysh9', emailTemplate, 'user_1mP7CPjx539MVUQwlnsrB')
      .then((_) => {
        dispatch({ type: "value", value: true, name: "success" });
      }, (err) => {
        console.log('FAILED...', err);
        dispatch({ type: "value", value: true, name: "fail" });
      });
  }

  return (
    <>
      <Layout
        title={t('site.pages.ContactPage.meta.title')}
        description={t('site.pages.ContactPage.meta.description')}
      >
        <div style={{ marginTop: 70 }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14417.410382255923!2d23.311571989234036!3d42.69500649076696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa856dd1517c85%3A0xffb5a61b370ddfaa!2sSofia%20Center%2C%20Sofia%2C%20Bulgaria!5e0!3m2!1sen!2suk!4v1599909743524!5m2!1sen!2suk" width="100%" height="550" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>

        <div className="container my-5">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-start align-items-start">
              <svg width="60" height="60" viewBox="0 0 16 16" className="bi bi-telephone-outbound" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
              </svg>

              <div className="ml-5">
                <h5>{t('site.pages.ContactPage.titles.phone')}</h5>
                <br />
                <a href="tel:+359886609877"><strong>+359 886 609 877</strong></a>
                <br />
                <br />
                <a href="tel:+359876365035"><strong>+359 876 365 035</strong></a>
              </div>
            </div>


            <div className="col-lg-4 d-flex justify-content-start align-items-start">
              <svg width="60" height="60" viewBox="0 0 16 16" className="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
              </svg>
              <div className="ml-5">
                <h5>{t('site.pages.ContactPage.titles.email')}</h5>
                <br />
                <a href="mailto:contact@ostrec.eu"><strong>contact@ostrec.eu</strong></a>
                <br />
                <br />
              </div>
            </div>


            <div className="col-lg-4 d-flex justify-content-start align-items-start">
              <svg width="60" height="60" viewBox="0 0 16 16" className="bi bi-geo-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
              </svg>

              <div className="ml-5">
                <h5>{t('site.pages.ContactPage.titles.location')}</h5>
                <br />
                <p>{t('site.pages.ContactPage.location')}</p>
              </div>
            </div>




          </div>
        </div>

        <div className="row d-flex justify-content-center mt-5 py-5 px-5 bg-light-gray">

          <div>
            <h2 className="text-center">{t('site.pages.ContactPage.form.title')}</h2>
            <hr className="my-4" />

            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label for="inputName">{t('site.pages.ContactPage.form.names')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    value={inputName}
                    placeholder={t('site.pages.ContactPage.form.names')}
                    onChange={(e) => {
                      dispatch({ type: "value", value: e.currentTarget.value, name: "inputName" });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label for="inputEmail">{t('site.pages.ContactPage.form.email')}</label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    value={inputEmail}
                    placeholder={t('site.pages.ContactPage.form.email')}
                    onChange={(e) => {
                      dispatch({ type: "value", value: e.currentTarget.value, name: "inputEmail" });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="inputAddress">{t('site.pages.ContactPage.form.message')}</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  value={inputMessage}
                  placeholder={t('site.pages.ContactPage.form.message')}
                  onChange={(e) => {
                    dispatch({ type: "value", value: e.currentTarget.value, name: "inputMessage" });
                  }}
                ></textarea>
              </div>
              <button type="submit" onClick={onSubmit} className="btn btn-primary text-center">{t('site.pages.ContactPage.form.btnText')}</button>

              {success && <p className="text-success"><small>{t('site.pages.ContactPage.form.success')}</small></p>}
              {fail && <p className="text-danger"><small>{t('site.pages.ContactPage.form.fail')}</small></p>}
            </form>

          </div>
        </div>



      </Layout>
    </>
  )
}

export const query = graphql`
  query ContactPage {
                    header: file(relativePath: {eq: "hero-scissors.jpg"}) {
                    childImageSharp {
                    sizes(quality: 100) {
                    src
                  }
      }
    }
  }
`;

export default withTrans(ContactPage);